@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --success: 141 84.2% 92.5%;
    --success-foreground: 144 61.2% 20.2%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

h1 {
  @apply text-2xl sm:text-4xl font-bold mb-4;
}

h2 {
  @apply text-xl sm:text-3xl font-bold;
}

h3 {
  @apply text-lg sm:text-2xl font-semibold mb-4;
}

.nav-link {
  @apply flex text-sm px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer;
}

.text-link {
  @apply underline underline-offset-4 font-semibold;

  &:hover {
    @apply font-bold;
  }
}

.center-section {
  @apply mx-auto max-w-4xl mt-12;
}

.container {
  @apply mx-auto max-w-7xl px-6 lg:px-8;
}

.tiny-table {
  img {
    @apply w-[150px];
  }
  td {
    @apply p-0;
    &.td-size {
      @apply text-center border !w-[45px];
    }
  }

  th {
    @apply px-2;
    &.td-size {
      @apply w-[45px];
    }
  }
}

.feature-blocks {
  @apply space-y-4 sm:space-y-12 sm:mt-12 mt-6;
  .feature-block {
    @apply flex sm:flex-row flex-col sm:items-center;

    &.reverse {
      @apply sm:flex-row-reverse;
    }
    .screen {
      @apply w-full sm:w-1/2 relative overflow-hidden pt-12 px-2.5;
      .image-wrapper {
        @apply mx-auto max-w-7xl px-6 lg:px-8;
        img {
          @apply mb-[-12%] rounded-lg shadow-2xl ring-1 ring-gray-900/10;
        }
        .bottom-gradient {
          @apply absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%];
        }
      }
    }
    .child {
      @apply sm:w-1/2 w-full sm:p-4 px-6;
      h2 {
        @apply mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:text-start text-center;
      }
      p {
        @apply mt-6 sm:leading-8 text-gray-600 sm:text-start text-center;
      }
    }
  }
}

.flagship-button {
  @apply bg-neil hover:bg-neil-400 rounded-none;
  &:hover {
    @apply bg-neil-400 !important;
  }
}

.flagship-button-outline {
  @apply bg-white text-neil hover:text-neil-400 hover:bg-accent border border-neil rounded-none;
  &:hover {
    @apply text-neil-400 bg-white hover:bg-accent !important;
  }
}

.flagship {
  @apply text-neil hover:text-neil-400;
  &:hover {
    @apply text-neil-400 !important;
  }
}

.admin-store {
  .text-link {
    @apply font-normal hover:font-normal hover:decoration-[2px];
  }
  .fx-badge {
    @apply text-xs leading-none bg-neil-100 rounded-full px-1.5 py-0.5 -mt-0.5 border border-neil-200 text-neil-500 w-fit flex flex-row items-center space-x-1;
  }
}

.crsr {
  @apply cursor-pointer;
}

@layer utilities {
  .space-y-default {
    @apply space-y-4 sm:space-y-5;
  }

  .gap-default {
    @apply gap-4 sm:gap-5;
  }
}

/* Pagination */
.pagination {
  @apply flex items-center gap-1;
}

.pagination__pageLink,
.pagination__prevLink,
.pagination__nextLink {
  @apply inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 gap-1;
}

.pagination__pageLink--active {
  @apply border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground;
}

.pagination__break {
  @apply p-2;
}

.table-data {
  div {
    div:first-child {
      @apply font-bold;
    }
  }
}

.hide-number-arrows::-webkit-outer-spin-button,
.hide-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-number-arrows {
  -moz-appearance: textfield;
}

.admin-store-wrapper {
  input,
  button,
  textarea {
    @apply focus-visible:ring-neil-200 focus-visible:ring-offset-1;
  }
}

.title-wrapper {
  @apply flex flex-row;
}

.sketch-picker {
  box-shadow: none !important;
}

.tiny-table-2 {
  thead tr th,
  tbody tr td {
    @apply h-auto p-1;
  }
  tbody tr td {
    @apply text-xs;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Uppy */

.popoverArtworkContent {
  .uppy-Dashboard-inner {
    width: 100% !important;
    height: auto !important;
  }

  .uppy-Dashboard-dropFilesHereHint {
    padding-top: 0 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .uppy-Dashboard-AddFiles {
    border: none !important;
    margin: 0 !important;
    background: #fff !important;
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
    padding: 0.75rem;
  }

  .uppy-Dashboard-AddFiles-list {
    margin-top: 0 !important;
  }

  .uppy-Dashboard-AddFiles-title {
    /* color: rgb(156 163 175 / var(--tw-text-opacity)) !important; */
    font-size: 1rem !important;
    /* line-height: 1.25rem !important; */
  }

  .uppy-Dashboard-AddFiles-list {
    display: none;
  }
}

.uppy-Dashboard-close {
  right: 10px !important;
  top: 10px !important;
  color: var(--primary-foreground) !important;
  line-height: 0.5 !important;
}

.uppy-StatusBar-actionBtn {
  @apply text-primary !important;
}

/* Popover */
.PopoverContent {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}

.centered-layout {
  h1 {
    @apply text-2xl text-center mb-1 mt-0;
  }
  h2 {
    @apply text-lg text-center text-muted-foreground font-normal;
  }
}

/*a {
  @apply font-bold;
  &:hover {
    @apply underline;
  }
}*/
